import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({
	title,
	description = ''
}) => {

	const formattedTitle = title ? `${title} – Action Etrange` : 'Action Etrange'

  return (
    <Helmet title={formattedTitle}>
      <meta name="description" content={description} />

      {title && <meta property="og:title" content={formattedTitle} />}
			{title && <meta name="twitter:title" content={formattedTitle} />}

      {description && <meta property="og:description" content={description} />}
			{description && <meta name="twitter:description" content={description} />}

			<meta property='og:url' content='https://actionetrange.tv/'/>

			{/* <meta name="robots" content="noindex" /> */}
    </Helmet>
  )
}

export default Seo