function detectElementsOnScreen () {

	const observers = []
	const elementsToDetect = [...document.querySelectorAll('[data-is-on-screen]')]

	elementsToDetect.forEach((el, index) => {
		const observer = new IntersectionObserver(
      ([entry]) => {
				if (entry.isIntersecting === true) {
					el.classList.add('onScreen')
				} else {
					el.classList.remove('onScreen')
				}
      }
    )
		observer.observe(el)
		observers.push(observer)
	})
	return [observers, elementsToDetect]
}

function killObservers(observers, elements) {
	observers.forEach((observer, index) => {
		observer.unobserve(elements[index]);
	})
}

export { detectElementsOnScreen, killObservers }